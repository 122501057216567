import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import Card from '../components/card'
import PageDescription from '../components/pageDescription'
import HeroImage from '../components/heroImage'
import Footer from '../components/footer'

const ActivitiesPage = ({ data: { datoCmsActivitiesPage: { name, description, heroImage, activities } } }) => {
  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={name}
          text={description}
        />
        {activities.map(({ name, media, description, originalId }) => (
          <Card
            key={originalId}
            title={name}
            text={description}
            media={media}
          />
        ))}
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query activitiesPage {
    datoCmsActivitiesPage {
      name
      description
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      activities {
        name
        description
        originalId
        media {
          id
          alt
          fluid(maxWidth: 1000, maxHeight: 650, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default ActivitiesPage
